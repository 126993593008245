@font-face {
  font-family: ApercuRegular;
  src: url("Apercu\ Regular.otf");
}

::-moz-selection { /* Code for Firefox */
  /* color: red; */
  background: purple;
}

::selection {
  /* color: red; */
  background: purple;
}


html {
  background-color: black;
  overflow-x: hidden;
  scroll-behavior: smooth;
  color: white;
}

/* h1, h2, h3, h4, h5 {
  margin: 0;
} */

ul {
  padding-left: 0;
}


.App {
  text-align: center;
  overflow-x: hidden;
  width: 100vw;
  /* height: 100vh; */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.particles-canvas-home {
  background: url("home_background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  cursor: default;
  overflow: hidden;
  scroll-snap-align: start;
  max-height: 100vh;
  /* position: absolute; */
}

.particles-canvas-projects {
  background-color: pink;
  background: url("projects_background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  cursor: default;
  overflow: hidden;
  /* position: absolute; */
}

.skills-container {
  position: absolute;
  margin-left: 5%;
  margin-right: 5%;
  top: 5%;
  left: 0;
  color: white;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.main-text-container {
  position: absolute;
  margin-left: 5%;
  margin-right: 5%;
  top: 35%;
  left: 0;
  color: white;
  /* display: flex;
  flex-direction:column; */
  text-align: left;
}

.main-text-container h1 {
  font-size: 72px;
  font-weight: 700;
}

.main-text-container h3 {
  width: fit-content;
}

a {
  color: rgb(230, 230, 230);
  text-decoration: none;
}

a:hover {
  color: white;
}

html * {
  font-family: ApercuRegular, "Source Sans Pro", Helvetica, Arial, sans-serif;
  /* font-family: "Helvetica Now Display", sans-serif; */
}

.inverted-color,
.inverted-color a {
  color: black;
  background-color: white;
}

li {
  list-style: none;
  line-height: 1.4;
  font-size: 1rem;
  text-align: left;
}

.color-white {
  color: white;
}

.color-black {
  color: black;
}

.profileSection {
  transition: opacity 1s;
  opacity: 1;
}

.experienceSection {
  /* background-color: green; */
}

.skillSection {
  /* background-color: pink; */
  /* padding-left: 30px;
  padding-right: 30px; */
}


.skillSection, .experienceSection, .profileSection {
  position: relative;
  height: fit-content;
  min-height: 100vh;
  fill: currentColor;
  padding: 50px 30px;
  text-align: left;
}


.invis,
.invis * {
  opacity: 0.3;
}

.profileSection p,
.experienceSection p,
.skillSection p, 
.profileSection h3,
.experienceSection h3,
.skillSection h3, 
.profileSection h4,
.experienceSection h4,
.skillSection h4, 
.profileSection h5,
.experienceSection h5,
.skillSection h5 {
  margin: 0;
  text-align: left;
}

h5 {
  font-size: 1rem;
}

.exp-desc, .exp-skills {
  font-size: 0.9rem;
}

.exp-desc {
  margin-top: 10px !important;
}

.exp-skills {
  margin: 0;
  margin-bottom: 15px;
}

/* .profileSection > div {
  top: 50%;
  vertical-align: middle;
} */

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f1f1f1e3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}

h3.section-font,
p.section-font,
span.section-font {
  /* padding-right: 30px;
  padding-left: 30px; */
}

.section-footer{
  font-size: 1.2rem;
  /* padding-right: 30px;
  padding-left: 30px; */
}

.section-footer a{
  /* padding-left: 10px; */
  padding-right: 20px;
}

.exp-skills > li{
  display: inline-block;
  padding: 3px 5px;
  margin-right: 10px;
  margin-top: 10px;
  border: 1px solid white;
  border-radius: 10px;
}

.section-font {
  text-align: left;
  font-size: 2rem;
}
.section-font-prim {
  text-align: left;
  font-size: 2rem;
}

.section-font-sec{
  /* text-align: left; */
  font-size: 1.3rem;
}

p.v-center,
span.v-center {
  text-align: left;
  font-size: 2rem;
  padding-right: 30px;
  padding-left: 30px;
}

.img-container * {
  display: block;
}

.section-title{
  /* padding-top: 50px; */
}

.section-title > h3{
  margin: 0px;
}

h3.section-font-sec {
  margin-top: 0px;
}

.section-desc{
  /* padding-top: 30px; */
}

.chevron-down-icon {
  top: 95%;
  left: 50%;
  position: absolute;
  margin: auto;
  /* transform: translate(-50%, -50%); */
  /* width: 40px;
  height: auto; */
  cursor: pointer;
  animation: pulse 1.5s infinite;
  /* border-radius: 50%; */
}

.experienceSection .section-desc {
  margin-bottom: 50px;
}

.profile-img {
  width: 158px;
  height: auto;
  object-fit: cover;
  border-radius: 50%;
  margin: auto;
}

@keyframes pulse {
  0% {
    color: grey;
    transform: scale(0.8);
  }

  50% {
    color: white;
    transform: scale(1);
  }

  100% {
    color: grey;
    transform: scale(0.8);
  }
}

@media only screen and (max-width: 980px) {
  .main-text-container h1 {
    font-size: 3rem;
  }
  .main-text-container h3 {
    font-size: 1rem;
  }

  .main-text-container {
    top: 20%;
  }

  /* p.v-center {
    font-size: 1.5rem;
  } */



  .section-font {
    font-size: 1.5rem;
  }

  .section-font-sec {
    font-size: 1.0rem;

  }


  .experienceSection .section-desc {
    margin-bottom: 70px;
  }

  /* .profileSection {
    height: fit-content;
    max-height: 100vh;
  } */

  .profileSection > div {
    height: fit-content;
    margin-top: 40px;
    margin-bottom: 20px;
    /* top: 50%;
    vertical-align: middle; */
  }
}

/* phone landscape */
@media (max-width: 900px){
  /* .section-desc{
    padding: 50px 0 30px;
  } */
  li {
    font-size: 0.9rem;
  }
  .section-footer{
    font-size: 1rem;
    padding-bottom: 50px;
  }
}


/* phone portrait */
@media (max-width: 600px){
  /* .section-desc{
    padding: 0 30px;
  } */

  li {
    font-size: 0.9rem;
  }

  .section-footer{
    font-size: 1rem;
    padding-bottom: 50px;
  }

  .experienceSection h3, .skillSection h3 {
    margin-bottom: 20px;
  }

  .experienceSection .section-desc {
    margin-bottom: 120px;
  }
}